import { Card, Table } from 'react-bootstrap';

export function GeneticGeneResultsTable(data) {
  return (
    <Card>
      <Card.Body>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Variants</th>
            </tr>
          </thead>
          <tbody>
            <tr key={'variants'}>
              <td>{data.results.join(', ')}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
