import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInputsContext } from 'App';
import { useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

export function CombClinicalInputForm() {
  const {
    inputs,
    dispatch,
    on_inputs_submitted: onInputsSubmitted
  } = useContext(SearchInputsContext);

  return (
    <Form onKeyUp={onInputsSubmitted}>
      <Row>
        <Form.Group as={Col} controlId="chromosome" xs={2}>
          <Form.Label>Chromosome</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: 1"
            defaultValue={inputs.chromosome ? inputs.chromosome : undefined}
            onChange={event =>
              dispatch({
                type: 'chromosome',
                value: event.target.value
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} controlId="positions" xs={3}>
          <Form.Label>Position</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: 13011, 13110, 13116"
            defaultValue={inputs.positions ? inputs.positions : undefined}
            onChange={event =>
              dispatch({
                type: 'positions',
                value: event.target.value
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} controlId="gt" xs={2}>
          <Form.Label>Genotype</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: 0, 1 or 2"
            defaultValue={inputs.gt ? inputs.gt : undefined}
            onChange={event =>
              dispatch({
                type: 'gt',
                value: event.target.value
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} controlId="sk" xs={3}>
          <Form.Label>Search Keys</Form.Label>
          <Form.Control
            type="text"
            placeholder="eg: 'all, domain or OMOP code to search'"
            defaultValue={inputs.sk ? inputs.sk : undefined}
            onChange={event =>
              dispatch({
                type: 'sk',
                value: event.target.value
              })
            }
          />
        </Form.Group>
        <Button
          xs={2}
          as={Col}
          id="submit-inputs"
          variant="falcon-default"
          className="mt-4"
          type="submit"
          onClick={onInputsSubmitted}
        >
          <span>
            <FontAwesomeIcon icon="search" /> Search
          </span>
        </Button>
      </Row>
      <Button
        onClick={() => {
          dispatch({
            type: 'multi',
            payload: {
              chromosome: '15',
              positions: '60650540',
              gt: '1',
              sk: 'condition'
            }
          });
          document.getElementById('chromosome').value = '15';
          document.getElementById('positions').value = '60650540';
          document.getElementById('gt').value = '1';
          document.getElementById('sk').value = 'condition';
        }}
        className="text-start mt-2"
        as={Col}
        size="sm"
        variant="link"
      >
        Click to prefill sample search keys
      </Button>
    </Form>
  );
}
