import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInputsContext } from 'App';
import { useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

export function ClinicalPersonInputForm() {
  const {
    inputs,
    dispatch,
    on_inputs_submitted: onInputsSubmitted
  } = useContext(SearchInputsContext);

  return (
    <Form onKeyUp={onInputsSubmitted}>
      <Row>
        <Form.Group as={Col} controlId="person_ids" xs={6}>
          <Form.Label>Person IDs</Form.Label>
          <Form.Control
            placeholder="eg: 43406"
            defaultValue={inputs.person_ids ? inputs.person_ids : undefined}
            onChange={event => {
              dispatch({
                type: 'person_ids',
                value: event.target.value
              });
            }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="sk" xs={4}>
          <Form.Label>Search Keys</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: 'all, domain or OMOP code to search'"
            defaultValue={inputs.sk ? inputs.sk : undefined}
            onChange={event =>
              dispatch({
                type: 'sk',
                value: event.target.value
              })
            }
          />
        </Form.Group>

        <Button
          xs={2}
          as={Col}
          id="submit-inputs"
          variant="falcon-default"
          className="mt-4"
          type="submit"
          onClick={onInputsSubmitted}
        >
          <span>
            <FontAwesomeIcon icon="search" /> Search
          </span>
        </Button>
      </Row>
      <Button
        onClick={() => {
          dispatch({
            type: 'multi',
            payload: { person_ids: '43406,62209', sk: 'all' }
          });
          document.getElementById('person_ids').value = '43406,62209';
          document.getElementById('sk').value = 'all';
        }}
        className="text-start mt-2"
        size="sm"
        variant="link"
      >
        Click here to prefill sample search keys
      </Button>
    </Form>
  );
}
