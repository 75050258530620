import { SearchTypeContext } from 'App';
import { ClinicalDomainInputForm } from 'multichain-components/input-forms/ClinicalDomainInputForm';
import { ClinicalPersonInputForm } from 'multichain-components/input-forms/ClinicalPersonInputForm';
import { CombClinicalInputForm } from 'multichain-components/input-forms/CombClinicalInputForm';
import { CombGeneticInputForm } from 'multichain-components/input-forms/CombGeneticInputForm';
import { GeneticAnnotInputForm } from 'multichain-components/input-forms/GeneticAnnotInputForm';
import { GeneticGeneInputForm } from 'multichain-components/input-forms/GeneticGeneInputForm';
import { GeneticMafInputForm } from 'multichain-components/input-forms/GeneticMafInputForm';
import { GeneticPersonInputForm } from 'multichain-components/input-forms/GeneticPersonInputForm';
import { ToggleSearchType } from 'multichain-components/input-forms/ToggleSearchType';
import { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { AnalysisMetadataInputForm } from './AnalysisMetadataInputForm';
import { AnalysisPcrInputForm } from './AnalysisPcrInputForm';
import { AnalysisRelatednessInputForm } from './AnalysisRelatednessInputForm';
import { GeneticVariantInputForm } from './GeneticVariantInputForm';

export function SearchBox() {
  const {
    analysisSearchType,
    search_type: searchType,
    clinical_search_type: clinicalSearchType,
    comb_search_type: combSearchType,
    genetic_search_type: geneticSearchType,
    setDescription: setDescription
  } = useContext(SearchTypeContext);

  let inputForm;

  switch (searchType) {
    case 'clinical':
      switch (clinicalSearchType) {
        case 'domain':
          inputForm = <ClinicalDomainInputForm />;
          setDescription(
            'Returns a table of patients and clinical information. Cohort Key field also takes OMOP code.'
          );
          break;
        case 'person':
          inputForm = <ClinicalPersonInputForm />;
          setDescription(
            'Returns a table with clinical information for the searched persons and related to search key'
          );
          break;
      }
      break;
    case 'genetic':
      switch (geneticSearchType) {
        case 'variant':
          inputForm = <GeneticVariantInputForm />;
          setDescription(
            'Returns a table of patients with the genotypes in the variants searched and additional genetic information related to variant (if applicable)'
          );
          break;
        case 'person':
          inputForm = <GeneticPersonInputForm />;
          setDescription(
            'Returns a table of variants and genotype for the person IDs searched'
          );
          break;
        case 'maf':
          inputForm = <GeneticMafInputForm />;
          setDescription(
            'Returns a table of variants and genotypes within MAF range'
          );
          break;
        case 'gene':
          inputForm = <GeneticGeneInputForm />;
          setDescription('Returns a list of position variants in gene');
          break;
        case 'annot':
          inputForm = <GeneticAnnotInputForm />;
          setDescription(
            'Returns a list of variants and their annotations in gene'
          );
          break;
      }
      break;
    case 'combination':
      switch (combSearchType) {
        case 'genetic':
          inputForm = <CombGeneticInputForm />;
          setDescription(
            'Returns a table of variants, genotypes, and person IDs within MAF range in particular gene. Cohort Key field also takes OMOP code.'
          );
          break;
        case 'clinical':
          inputForm = <CombClinicalInputForm />;
          setDescription(
            'Returns a table of clinical data for persons with the searched variant i.e. position and genotype'
          );
          break;
      }
      break;
    case 'analysis':
      switch (analysisSearchType) {
        case 'pca':
          inputForm = <AnalysisPcrInputForm />;
          setDescription(
            'Table of  PCs for sample ID - each column is a loading ordered from 1-20'
          );
          break;
        case 'kinship':
          inputForm = <AnalysisRelatednessInputForm />;
          setDescription(
            'Symmetric table showing the relationship closeness between every pair of samples. ID = Identical, PO = Parent Offspring, FS = First sibling, D2 = 2nd Degree relative , D3 = 3rd degree relative , UR = Unrelated'
          );
          break;
        case 'metadata':
          inputForm = <AnalysisMetadataInputForm />;
          setDescription('List of patients with corresponding metadata');
          break;
      }
  }

  return (
    <Card className="search-box" style={{ width: '100%' }}>
      <Card.Body>
        {inputForm}
        <ToggleSearchType />
        {/* {error ? (
                <Alert key="danger" variant="danger" dissmissable>
                    Search failed with an error
                </Alert>
            ) : null} */}
      </Card.Body>
    </Card>
  );
}
