import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInputsContext } from 'App';
import cohortKeys from 'multichain-components/data/large-cohort-keys';
import { useContext, useRef } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

export function CombGeneticInputForm() {
  const {
    inputs,
    dispatch,
    updateCohortKeys,
    filteredCohorts,
    on_inputs_submitted: onInputsSubmitted
  } = useContext(SearchInputsContext);

  const cohortRef = useRef();
  const domainRef = useRef();

  const domainOptions = [
    { value: 'Condition', label: 'Condition' },
    { value: 'Drug', label: 'Drug' },
    { value: 'Measurement', label: 'Measurement' },
    { value: 'Observation', label: 'Observation' },
    { value: 'Procedure', label: 'Procedure' },
    { value: 'Visit', label: 'Visit' },
    { value: 'Demographic', label: 'Demographic' }
  ];

  return (
    <Form onKeyUp={onInputsSubmitted}>
      <Row>
        <Form.Group as={Col} controlId="chromosome" xs={1}>
          <Form.Label>Chromosome</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: 3"
            defaultValue={inputs.chromosome ? inputs.chromosome : undefined}
            onChange={event =>
              dispatch({
                type: 'chromosome',
                value: event.target.value
              })
            }
          ></Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="gene" xs={2}>
          <Form.Label>Gene</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: ENSG00000223587.2"
            defaultValue={inputs.gene ? inputs.gene : undefined}
            onChange={event =>
              dispatch({
                type: 'gene',
                value: event.target.value
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} controlId="ir" xs={2}>
          <Form.Label>MAF Range</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: 0.1-0.2"
            defaultValue={inputs.ir ? inputs.ir : undefined}
            onChange={event =>
              dispatch({
                type: 'ir',
                value: event.target.value
              })
            }
          />
        </Form.Group>

        <Form.Group as={Col} controlId="domVal" xs={2}>
          <Form.Label>Domain</Form.Label>
          <CreatableSelect
            closeMenuOnSelect={true}
            options={domainOptions}
            ref={domainRef}
            placeholder="Select domain: eg condition"
            onChange={target => {
              updateCohortKeys(target.value);
            }}
            id="domainValue"
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="ck" xs={3}>
          <Form.Label>Cohort Key</Form.Label>
          <CreatableSelect
            closeMenuOnSelect={true}
            options={filteredCohorts}
            id="disease2"
            ref={cohortRef}
            placeholder="eg start typing diagnosis name"
            onChange={target => {
              dispatch({
                type: 'ck',
                value: target.value
              });
            }}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          />
        </Form.Group>
        <Button
          xs={2}
          as={Col}
          id="submit-inputs"
          variant="falcon-default"
          className="mt-4"
          type="submit"
          onClick={onInputsSubmitted}
        >
          <span>
            <FontAwesomeIcon icon="search" /> Search
          </span>
        </Button>
      </Row>
      <Button
        onClick={() => {
          document.getElementById('chromosome').value = '4';
          document.getElementById('ir').value = '0.9-1';
          document.getElementById('gene').value = 'ENSG00000169020.10';
          domainRef.current.setValue([
            { value: 'Condition', label: 'Condition' }
          ]);
          cohortRef.current.setValue([
            { value: '201826', label: 'Type 2 diabetes mellitus' }
          ]);
          dispatch({
            type: 'multi',
            payload: {
              chromosome: '4',
              ir: '0.9-1',
              gene: 'ENSG00000169020.10',
              ck: '201826'
            }
          });
        }}
        className="text-start mt-2"
        as={Col}
        size="sm"
        variant="link"
      >
        Click to prefill sample search keys
      </Button>
    </Form>
  );
}
