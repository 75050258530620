import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInputsContext } from 'App';
import { useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

export function GeneticAnnotInputForm() {
  const {
    inputs,
    dispatch,
    on_inputs_submitted: onInputsSubmitted
  } = useContext(SearchInputsContext);

  return (
    <Form onKeyUp={onInputsSubmitted}>
      <Row>
        <Form.Group as={Col} controlId="chromosome" xs={2}>
          <Form.Label>Chromosome</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: 1"
            defaultValue={inputs.chromosome ? inputs.chromosome : undefined}
            onChange={event =>
              dispatch({
                type: 'chromosome',
                value: event.target.value
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} controlId="gene" xs={3}>
          <Form.Label>Gene</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: ENSG00000124535.16"
            defaultValue={inputs.gene ? inputs.gene : undefined}
            onChange={event =>
              dispatch({
                type: 'gene',
                value: event.target.value
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} controlId="annot" xs={2}>
          <Form.Label>Annotation</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: cadd"
            defaultValue={inputs.annot ? inputs.annot : undefined}
            onChange={event =>
              dispatch({
                type: 'annot',
                value: event.target.value
              })
            }
          />
        </Form.Group>
        <Button
          xs={2}
          as={Col}
          id="submit-inputs"
          variant="falcon-default"
          className="mt-4"
          type="submit"
          onClick={onInputsSubmitted}
        >
          <span>
            <FontAwesomeIcon icon="search" /> Search
          </span>
        </Button>
      </Row>
      <Button
        onClick={() => {
          dispatch({
            type: 'multi',
            payload: { chromosome: '14', annot: 'cadd', gene: '' }
          });
          document.getElementById('chromosome').value = '14';
          document.getElementById('annot').value = 'cadd';
          document.getElementById('gene').value = '';
        }}
        className="text-start mt-2"
        as={Col}
        size="sm"
        variant="link"
      >
        Click to prefill sample search keys
      </Button>
    </Form>
  );
}
