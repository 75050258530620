import {
  SearchInputsContext,
  extract_person_ids,
  genotype_map,
  variant_text
} from 'App';
import groupBy from 'lodash/groupBy';
import { useContext } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Tab,
  Table,
  Tabs
} from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Persons } from './Persons';

export function GeneticPersonResultsTable(data) {
  const {
    on_person_hover: onPersonHover,
    person_data: personData,
    person_loading: personLoading
  } = useContext(SearchInputsContext);

  const person_popover = Persons(personData);

  const grouped_data = groupBy(data.results, 'chr');
  const chrom_keys = Object.keys(grouped_data);

  return (
    <Card>
      <Card.Body>
        {personLoading ? (
          <Container>
            <Row className="justify-content-center mt-4">
              <Col xs={1}>
                <Spinner animation="border" variant="success" />
              </Col>
            </Row>
          </Container>
        ) : null}
        <Tabs id="clinical-person">
          {chrom_keys.map((chromosome, index) => {
            return (
              <Tab
                eventKey={chromosome}
                title={'Chromosome ' + chromosome}
                className="border-bottom border-x p-3"
                key={index + '_row'}
              >
                <Table striped bordered hover size="sm" responsive="sm">
                  <thead>
                    <tr>
                      <th>Variant</th>
                      <th>Person</th>
                      <th>Genotype</th>
                    </tr>
                  </thead>
                  <tbody>
                    {grouped_data[chromosome].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="col-md-2">{variant_text(item)}</td>
                          <td className="col-md-1">
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={person_popover}
                              rootClose={true}
                            >
                              <Button
                                className="text-start"
                                variant="link"
                                onClick={e =>
                                  onPersonHover(
                                    e,
                                    extract_person_ids(item).join(', ')
                                  )
                                }
                              >
                                {extract_person_ids(item).map(idx => {
                                  return (
                                    <div key={idx}>
                                      {' '}
                                      {idx} <br />{' '}
                                    </div>
                                  );
                                })}
                              </Button>
                            </OverlayTrigger>
                          </td>
                          <td>
                            {extract_person_ids(item).map(idx => {
                              return (
                                <div key={idx}>
                                  {' '}
                                  {genotype_map[item['gt_' + idx]]} <br />{' '}
                                </div>
                              );
                            })}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Tab>
            );
          })}
        </Tabs>
      </Card.Body>
    </Card>
  );
}
