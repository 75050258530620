import { Table } from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';

export const Persons = data => (
  <Popover id="popover-basic" style={{ maxWidth: '100%' }}>
    <Popover.Header as="h3">Person Demographics</Popover.Header>
    <Popover.Body>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>person_id</th>
            <th>year_of_birth</th>
            <th>gender_source_value</th>
            <th>race_source_value</th>
            <th>ethnicity_value</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.person_id}</td>
                  <td>{item.year_of_birth}</td>
                  <td>{item.gender_source_value}</td>
                  <td>{item.race_source_value}</td>
                  <td>{item.ethnicity_value}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Popover.Body>
  </Popover>
);
