import axios from 'axios';
import Flex from 'components/common/Flex';
import Section from 'components/common/Section';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

const LoginForm = props => {
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  // Handler
  function logMeIn(event) {
    axios({
      method: 'POST',
      url: '/api/token',
      data: {
        email: formData.email,
        password: formData.password
      }
    })
      .then(response => {
        props.setToken(response.data.access_token);
        props.setPage('search');
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });

    setFormData({
      email: '',
      password: ''
    });

    event.preventDefault();
  }

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Section fluid className="py-0">
      <Row className="g-0 min-vh-100 flex-center">
        <Col md={4} as={Flex} alignItems="center" justifyContent="center">
          <div className="p-4 p-md-5 flex-grow-1">
            <Form onSubmit={logMeIn}>
              <Form.Label column="lg">Login</Form.Label>
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  placeholder="Email address"
                  value={formData.email}
                  name="email"
                  onChange={handleFieldChange}
                  type="email"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  placeholder="Password"
                  value={formData.password}
                  name="password"
                  onChange={handleFieldChange}
                  type="password"
                />
              </Form.Group>

              <Form.Group>
                <Button
                  type="submit"
                  color="primary"
                  className="mt-3 w-100"
                  disabled={!formData.email || !formData.password}
                >
                  Log in
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Col>
      </Row>
    </Section>
  );
};

LoginForm.propTypes = {
  setToken: PropTypes.func,
  setPage: PropTypes.func
};

export default LoginForm;
