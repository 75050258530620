import Table from 'react-bootstrap/Table';
import Popover from 'react-bootstrap/Popover';
import groupBy from 'lodash/groupBy';

export function Annotations(data) {
  const filtered_data = data.filter((value, index, self) => {
    return (
      self.findIndex(
        item =>
          item.cadd[0] === value.cadd[0] &&
          item.cadd[1] === value.cadd[1] &&
          item.cadd[2] === value.cadd[2] &&
          item.cadd[3] === value.cadd[3] &&
          item.cadd[4] === value.cadd[4] &&
          item.cadd[5] === value.cadd[5]
      ) === index
    );
  });

  console.log(filtered_data);
  return (
    <Popover id="popover-basic" style={{ maxWidth: '100%' }}>
      <Popover.Header as="h3">Annotations</Popover.Header>
      <Popover.Body>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Annotation</th>
              <th>AnnoType</th>
              <th>Consequence</th>
              <th>ConsScore</th>
              <th>ConsDetail</th>
              <th>RawScore</th>
              <th>PHRED</th>
            </tr>
          </thead>
          <tbody>
            {filtered_data &&
              filtered_data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{'cadd'}</td>
                    <td>{item.cadd[0]}</td>
                    <td>{item.cadd[1]}</td>
                    <td>{item.cadd[2]}</td>
                    <td>{item.cadd[3]}</td>
                    <td>{item.cadd[4]}</td>
                    <td>{item.cadd[5]}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Popover.Body>
    </Popover>
  );
}
