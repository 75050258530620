import { SearchInputsContext, genotype_map, more_text } from 'App';
import { useContext } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Table
} from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Persons } from './Persons';

export function AnalysisRelatednessResultsTable(data) {
  const {
    on_person_hover: onPersonHover,
    person_data: personData,
    person_loading: personLoading
  } = useContext(SearchInputsContext);

  const person_popover = Persons(personData);

  return (
    <Card>
      <Card.Body>
        {personLoading ? (
          <Container>
            <Row className="justify-content-center mt-4">
              <Col xs={1}>
                <Spinner animation="border" variant="success" />
              </Col>
            </Row>
          </Container>
        ) : null}

        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Person</th>
              <th>ID</th>
              <th>PO</th>
              <th>FS</th>
              <th>D2</th>
              <th>D3</th>
              <th>UR</th>
            </tr>
          </thead>
          <tbody>
            {data.results &&
              Object.keys(data.results).map(key => {
                return (
                  <tr key={key}>
                    {/* <td>{item.person_ids}</td> */}
                    <td>
                      <OverlayTrigger
                        trigger="click"
                        placement="right"
                        overlay={person_popover}
                        rootClose={true}
                      >
                        <Button
                          className="text-start"
                          variant="link"
                          onClick={e => onPersonHover(e, key)}
                        >
                          {key}
                        </Button>
                      </OverlayTrigger>
                    </td>
                    <td>
                      {data.results[key].ID && data.results[key].ID.join(', ')}
                    </td>
                    <td>
                      {data.results[key].PO && data.results[key].PO.join(', ')}
                    </td>
                    <td>
                      {data.results[key].FS && data.results[key].FS.join(', ')}
                    </td>
                    <td>
                      {data.results[key].D2 && data.results[key].D2.join(', ')}
                    </td>
                    <td>
                      {data.results[key].D3 && data.results[key].D3.join(', ')}
                    </td>
                    <td>
                      {data.results[key].UR && data.results[key].UR.join(', ')}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
