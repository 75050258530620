import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInputsContext } from 'App';
import { useContext, useRef } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

export function ClinicalDomainInputForm() {
  const {
    inputs,
    dispatch,
    updateCohortKeys,
    filteredCohorts,
    on_inputs_submitted: onInputsSubmitted
  } = useContext(SearchInputsContext);

  const domainRef = useRef();
  const cohortRef = useRef();

  const domainOptions = [
    { value: 'Condition', label: 'Condition' },
    { value: 'Drug', label: 'Drug' },
    { value: 'Measurement', label: 'Measurement' },
    { value: 'Observation', label: 'Observation' },
    { value: 'Procedure', label: 'Procedure' },
    { value: 'Visit', label: 'Visit' },
    { value: 'Demographic', label: 'Demographic' }
  ];

  return (
    <Form onKeyUp={onInputsSubmitted}>
      <Row>
        <Form.Group as={Col} controlId="domVal" xs={2}>
          <Form.Label>Domain</Form.Label>
          <CreatableSelect
            closeMenuOnSelect={true}
            options={domainOptions}
            placeholder="Select domain: eg condition"
            ref={domainRef}
            onChange={target => {
              updateCohortKeys(target.value);
            }}
            id="domainValue"
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="ck" xs={4}>
          <Form.Label>Cohort Key</Form.Label>
          <CreatableSelect
            closeMenuOnSelect={true}
            options={filteredCohorts}
            ref={cohortRef}
            placeholder="eg start typing diagnosis name"
            onChange={target => {
              dispatch({
                type: 'ck',
                value: target.value
              });
            }}
            id="disease"
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="sk" xs={4}>
          <Form.Label>Search Keys</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: 'all, domain or OMOP code to search'"
            defaultValue={inputs.sk ? inputs.sk : undefined}
            onChange={event =>
              dispatch({
                type: 'sk',
                value: event.target.value
              })
            }
          />
        </Form.Group>

        <Button
          xs={2}
          as={Col}
          id="submit-inputs"
          variant="falcon-default"
          className="mt-4"
          type="submit"
          onClick={onInputsSubmitted}
        >
          <span>
            <FontAwesomeIcon icon="search" /> Search
          </span>
        </Button>
      </Row>
      <Button
        onClick={() => {
          domainRef.current.setValue([
            { value: 'Condition', label: 'Condition' }
          ]);
          cohortRef.current.setValue([
            { value: '4329847', label: 'Myocardial infarction' }
          ]);
          cohortRef.current.options = filteredCohorts;
          dispatch({
            type: 'multi',
            payload: { ck: '4329847', sk: 'condition, drug' }
          });
        }}
        className="text-start mt-2"
        size="sm"
        variant="link"
      >
        Click here to prefill sample search keys
      </Button>
    </Form>
  );
}
