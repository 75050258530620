import { SearchInputsContext, genotype_map, more_text } from 'App';
import { useContext } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Table
} from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Persons } from './Persons';
import groupBy from 'lodash/groupBy';

export function AnalysisPcaResultsTable(data) {
  const {
    inputs,
    on_person_hover: onPersonHover,
    person_data: personData,
    person_loading: personLoading
  } = useContext(SearchInputsContext);

  const person_popover = Persons(personData);

  console.log(inputs.kSearch);
  return (
    <Card>
      <Card.Body>
        {personLoading ? (
          <Container>
            <Row className="justify-content-center mt-4">
              <Col xs={1}>
                <Spinner animation="border" variant="success" />
              </Col>
            </Row>
          </Container>
        ) : null}

        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Person</th>
              {data.results &&
                data.results.map &&
                data.results[0] &&
                data.results[0].pca.map((item, index) => {
                  return <th key={index}>PC{index + 1}</th>;
                })}
            </tr>
          </thead>
          <tbody>
            {data.results &&
              data.results.map &&
              data.results.map((item, index) => {
                return (
                  <tr key={index}>
                    {/* <td>{item.person_ids.join(', ')}</td> */}
                    <td>
                      <OverlayTrigger
                        trigger="click"
                        placement="right"
                        overlay={person_popover}
                        rootClose={true}
                      >
                        <Button
                          className="text-start"
                          variant="link"
                          onClick={e => onPersonHover(e, item.person_id)}
                        >
                          {item.person_id}
                        </Button>
                      </OverlayTrigger>
                    </td>
                    {item.pca.map((pca, index) => {
                      return <td key={index}>{pca}</td>;
                    })}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
