import { SearchInputsContext, genotype_map, more_text } from 'App';
import { useContext } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Table
} from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Persons } from './Persons';
import { Annotations } from './Annotations';

export function CombGeneticResultsTable(data) {
  const {
    on_person_hover: onPersonHover,
    person_data: personData,
    person_loading: personLoading
  } = useContext(SearchInputsContext);

  const [results, annotations] = data.results;

  const person_popover = Persons(personData);

  return (
    <Card>
      <Card.Body>
        {personLoading ? (
          <Container>
            <Row className="justify-content-center mt-4">
              <Col xs={1}>
                <Spinner animation="border" variant="success" />
              </Col>
            </Row>
          </Container>
        ) : null}
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Person IDs</th>
              <th>Variant</th>
              <th>Genotype</th>
              <th className="text-end">MAF</th>
            </tr>
          </thead>
          <tbody>
            {results &&
              results.map &&
              results.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <OverlayTrigger
                        trigger="click"
                        placement="right"
                        overlay={person_popover}
                        rootClose={true}
                      >
                        <Button
                          className="text-start"
                          variant="link"
                          onClick={e =>
                            onPersonHover(e, item.person_ids.join(', '))
                          }
                        >
                          {item.person_ids[0] +
                            more_text(item.person_ids.length)}
                        </Button>
                      </OverlayTrigger>
                    </td>
                    <td>
                      <OverlayTrigger
                        trigger="click"
                        placement="right"
                        overlay={Annotations(
                          annotations[item.variant.split(':')[1]]
                        )}
                        rootClose={true}
                      >
                        <Button className="text-start" variant="link">
                          {item.variant}
                        </Button>
                      </OverlayTrigger>
                    </td>
                    <td>{genotype_map[item.gt]}</td>
                    <td className="text-end">{item.maf}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
