import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import PropTypes from 'prop-types';
// Removed unused import statement
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const columns = [
  {
    accessor: 'query',
    Header: 'Query',
    Cell: rowData => (
      <Link to="#!" className="text-primary fw-semi-bold">
        {rowData.row.original.query}
      </Link>
    )
  },
  {
    accessor: 'time',
    Header: 'Time',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    }
  },
  {
    accessor: 'wallet',
    Header: 'Wallet',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    }
  }
];

const RecentQueries = ({ tableData, perPage = 8 }) => {
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={tableData}
      pagination
      perPage={perPage}
    >
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs="auto" sm={6} lg={7}>
              <h6 className="mb-0 text-nowrap py-2 py-xl-0">
                Realtime Queries
              </h6>
            </Col>
            <Col xs="auto" sm={6} lg={5}>
              <AdvanceTableSearchBox table placeholder="Search for a Query" />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={tableData.length}
            table
            rowInfo
            navButtons
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

RecentQueries.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      query: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
      wallet: PropTypes.string.isRequired
    })
  ).isRequired,
  perPage: PropTypes.number
};

export default RecentQueries;
