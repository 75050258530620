import { SearchTypeContext } from 'App';
import { useContext } from 'react';
import {
  ButtonGroup,
  Card,
  CardGroup,
  Col,
  Button,
  ToggleButton,
  ToggleButtonGroup
} from 'react-bootstrap';

export function ToggleSearchType() {
  const {
    analysisSearchType,
    setAnalysisSearchType,
    search_type: searchType,
    set_search_type: setSearchType,
    clinical_search_type: clinicalSearchType,
    set_clinical_search_type: setClinicalSearchType,
    comb_search_type: combSearchType,
    set_comb_search_type: setCombSearchType,
    genetic_search_type: geneticSearchType,
    set_genetic_search_type: setGeneticSearchType,
    description: description
  } = useContext(SearchTypeContext);

  return (
    <CardGroup>
      <Card className="mt-3" as={Col} xs={5}>
        <Card.Body>
          <Card.Title>Search Type:</Card.Title>

          <ToggleButtonGroup value={searchType} name="search-options">
            <ToggleButton
              variant={'outline-primary'}
              id="tbg-btn-clinical"
              key="clinical"
              value="clinical"
              checked={searchType === 'clinical'}
              name="radio"
              type="radio"
              onChange={e => setSearchType(e.currentTarget.value)}
            >
              Clinical
            </ToggleButton>

            <ToggleButton
              variant={'outline-primary'}
              id="tbg-btn-variant"
              key="genetic"
              value="genetic"
              checked={searchType === 'genetic'}
              name="radio"
              type="radio"
              onChange={e => setSearchType(e.currentTarget.value)}
            >
              Genetic
            </ToggleButton>

            <ToggleButton
              variant="outline-primary"
              id="tbg-btn-maf"
              key="combination"
              value="combination"
              checked={searchType === 'combination'}
              name="radio"
              type="radio"
              onChange={e => setSearchType(e.currentTarget.value)}
            >
              Combination
            </ToggleButton>

            <ToggleButton
              variant="outline-primary"
              id="tbg-btn-analysis"
              key="analysis"
              value="analysis"
              checked={searchType === 'analysis'}
              name="radio"
              type="radio"
              onChange={e => setSearchType(e.currentTarget.value)}
            >
              Analysis
            </ToggleButton>
          </ToggleButtonGroup>
          <br />
          {searchType == 'clinical' ? (
            <>
              <Card.Title className="mt-2">Clinical Search Type:</Card.Title>
              <ButtonGroup value={clinicalSearchType}>
                <ToggleButton
                  variant="outline-primary"
                  id="tbg-btn-variant-comb"
                  key="comb-variant"
                  value="domain"
                  checked={clinicalSearchType === 'domain'}
                  name="radio"
                  type="radio"
                  onChange={e => setClinicalSearchType(e.currentTarget.value)}
                >
                  Domain
                </ToggleButton>
                <ToggleButton
                  variant="outline-primary"
                  id="tbg-btn-gene-comb"
                  key="var-person"
                  value="person"
                  checked={clinicalSearchType === 'person'}
                  name="radio"
                  type="radio"
                  onChange={e => setClinicalSearchType(e.currentTarget.value)}
                >
                  Person
                </ToggleButton>
              </ButtonGroup>
            </>
          ) : null}

          {searchType == 'genetic' ? (
            <>
              <Card.Title className="mt-2">Genetic Search Type:</Card.Title>

              <ButtonGroup value={geneticSearchType}>
                <ToggleButton
                  variant="outline-primary"
                  id="tbg-btn-variant-comb"
                  key="comb-variant"
                  value="variant"
                  checked={geneticSearchType === 'variant'}
                  name="radio"
                  type="radio"
                  onChange={e => setGeneticSearchType(e.currentTarget.value)}
                >
                  Variant
                </ToggleButton>
                <ToggleButton
                  variant="outline-primary"
                  id="tbg-btn-gene-comb"
                  key="var-person"
                  value="person"
                  checked={geneticSearchType === 'person'}
                  name="radio"
                  type="radio"
                  onChange={e => setGeneticSearchType(e.currentTarget.value)}
                >
                  Person
                </ToggleButton>
                <ToggleButton
                  variant="outline-primary"
                  id="tbg-btn-maf-genetic"
                  key="var-maf"
                  value="maf"
                  checked={geneticSearchType === 'maf'}
                  name="radio"
                  type="radio"
                  onChange={e => setGeneticSearchType(e.currentTarget.value)}
                >
                  MAF
                </ToggleButton>

                <ToggleButton
                  variant="outline-primary"
                  id="tbg-btn-gene-genetic"
                  key="genetic-gene"
                  value="gene"
                  checked={geneticSearchType === 'gene'}
                  name="radio"
                  type="radio"
                  onChange={e => setGeneticSearchType(e.currentTarget.value)}
                >
                  Gene
                </ToggleButton>
                <ToggleButton
                  variant="outline-primary"
                  id="tbg-btn-genetic-annot"
                  key="genetic-annot"
                  value="annot"
                  checked={geneticSearchType === 'annot'}
                  name="radio"
                  type="radio"
                  onChange={e => setGeneticSearchType(e.currentTarget.value)}
                >
                  Annotation
                </ToggleButton>
              </ButtonGroup>
            </>
          ) : null}
          {searchType == 'combination' ? (
            <>
              <Card.Title className="mt-2">Combination Search Type:</Card.Title>
              <ButtonGroup value={combSearchType}>
                <ToggleButton
                  variant="outline-primary"
                  id="tbg-btn-genetic-comb"
                  key="comb-genetic"
                  value="genetic"
                  checked={combSearchType === 'genetic'}
                  name="radio"
                  type="radio"
                  onChange={e => setCombSearchType(e.currentTarget.value)}
                >
                  Clinical
                </ToggleButton>

                <ToggleButton
                  variant="outline-primary"
                  id="tbg-btn-clinical-comb"
                  key="comb-clinical"
                  value="clinical"
                  checked={combSearchType === 'clinical'}
                  name="radio"
                  type="radio"
                  onChange={e => setCombSearchType(e.currentTarget.value)}
                >
                  Genetic
                </ToggleButton>
              </ButtonGroup>
            </>
          ) : null}

          {searchType == 'analysis' ? (
            <>
              <Card.Title className="mt-2">Analysis Search Type:</Card.Title>
              <ButtonGroup value={combSearchType}>
                <ToggleButton
                  variant="outline-primary"
                  id="tbg-btn-analysis-pcr"
                  key="pca"
                  value="pca"
                  checked={analysisSearchType === 'pca'}
                  name="radio"
                  type="radio"
                  onChange={e => setAnalysisSearchType(e.currentTarget.value)}
                >
                  PCA
                </ToggleButton>

                <ToggleButton
                  variant="outline-primary"
                  id="tbg-btn-analysis-relatedness"
                  key="kinship"
                  value="kinship"
                  checked={analysisSearchType === 'kinship'}
                  name="radio"
                  type="radio"
                  onChange={e => setAnalysisSearchType(e.currentTarget.value)}
                >
                  Relatedness
                </ToggleButton>

                <ToggleButton
                  variant="outline-primary"
                  id="tbg-btn-analysis-metadata"
                  key="metadata"
                  value="metadata"
                  checked={analysisSearchType === 'metadata'}
                  name="radio"
                  type="radio"
                  onChange={e => setAnalysisSearchType(e.currentTarget.value)}
                >
                  Metadata
                </ToggleButton>
                <Button variant="link" className="me-2 mb-1 bottom-0 end-0">
                  <a
                    href="https://precisionchain.g2lab.org/notebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Go to Analysis Workbook
                  </a>
                </Button>
              </ButtonGroup>
            </>
          ) : null}
        </Card.Body>
      </Card>
      <Card className="mt-3" as={Col} xs={7}>
        <Card.Body>
          <Card.Title>Search Description:</Card.Title>
          <Card.Text style={{ fontSize: '16px' }}>{description}</Card.Text>
        </Card.Body>
      </Card>
    </CardGroup>
  );
}
