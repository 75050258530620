/* eslint-disable prettier/prettier */
/* eslint-disable react/react-in-jsx-scope */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Section from 'components/common/Section';
import RecentQueries from 'multichain-components/RecentQueries';
import useToken from 'multichain-components/auth-token';
import cohortKeys from 'multichain-components/data/large-cohort-keys';
import metadataMap from 'multichain-components/data/metadata-map';
import dnaBackground from 'multichain-components/images/nodes-clinical-dna-lock.png';
import LoginForm from 'multichain-components/login';
import GeneticAnnotResultsTable from 'multichain-components/result-tables/GeneticAnnotResultsTable';
import demoVideo from 'multichain-components/videos/precision_chain_video.mp4';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/lazy';


import { SearchBox } from 'multichain-components/input-forms/SearchBox';
import { ClinicalResultsTable } from 'multichain-components/result-tables/ClinicalResultsTable';
import { CombGeneticResultsTable } from 'multichain-components/result-tables/CombGeneticResultsTable';
import { GeneticGeneResultsTable } from 'multichain-components/result-tables/GeneticGeneResultsTable';
import { GeneticMafResultsTable } from 'multichain-components/result-tables/GeneticMafResultsTable';
import { GeneticPersonResultsTable } from 'multichain-components/result-tables/GeneticPersonResultsTable';
import { GeneticVariantResultsTable } from 'multichain-components/result-tables/GeneticVariantResultsTable';
import { AnalysisPcaResultsTable } from 'multichain-components/result-tables/AnalysisPcaResultsTable';
import { AnalysisRelatednessResultsTable } from 'multichain-components/result-tables/AnalysisRelatednessResultsTable';
import { AnalysisMetadataResultsTable } from 'multichain-components/result-tables/AnalysisMetadataResultsTable';
import {
  createContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Nav, Navbar, Row, Spinner
} from 'react-bootstrap';
import { BrowserRouter } from 'react-router-dom';
export const SearchInputsContext = createContext();
export const SearchTypeContext = createContext();

// function useForceUpdate() {
//     const [value, setValue] = useState(0); // integer state
//     typeof (value);
//     return () => setValue(value => value + 1); // update state to force render
//     // An function that increment 👆🏻 the previous state like here
//     // is better than directly setting `value + 1`
// }


function App() {

  const initialState = {
    chromosome: '',
    gene: '',
    ck: '',
    ir: '',
    positions: '',
    sk: '',
    gt: '',
    person_ids: '',
    annot: '',
    sampleId: '',
    kSearch: '',
    metadata: ''
  };


  const { token, removeToken, setToken } = useToken();
  const [data, setdata] = useState([]);
  const [error, setError] = useState(false);
  const [auditData, setAuditData] = useState([]);
  const [auditWalletData, setAuditWalletData] = useState([]);
  const [searchType, setSearchType] = useState('clinical');
  const [clinicalSearchType, setClinicalSearchType] = useState('domain');
  const [lastClinicalSearchType, setLastClinicalSearchType] = useState('');
  const [combSearchType, setCombSearchType] = useState('genetic');
  const [geneticSearchType, setGeneticSearchType] = useState('variant');
  const [lastGeneticSearchType, setLastGeneticSearchType] = useState('');
  const [lastSearchType, setLastSearchType] = useState('');
  const [lastCombSearchType, setLastCombSearchType] = useState('');
  const [analysisSearchType, setAnalysisSearchType] = useState('pca');
  const [lastAnalysisSearchType, setLastAnalysisSearchType] = useState('');
  const [loading, setLoading] = useState(false);
  const [personLoading, setPersonLoading] = useState(false);
  const [searchInputsState, dispatch] = useReducer(
    searchInputsReducer,
    initialState
  );
  const [page, setPage] = useState('home');
  const [personData, setPersonData] = useState([]);
  const [description, setDescription] = useState('')
  const [filteredCohorts, setFilteredCohorts] = useState(cohortKeys);
  const [filteredMetadata, setFilteredMetadata] = useState(metadataMap);



  typeof (auditWalletData);

  const setPageWithChecks = (key) => {
    if (token && (key === 'search' || key === 'admin')) {
      setPage(key)
    } else if (!token && (key === 'search' || key === 'admin')) {
      setPage('login')
    } else (
      setPage(key)
    )
  }



  const onInputsSubmitted = e => {
    e.preventDefault();
    if (e.keyCode === 13 || e.type === 'click') {
      setError(false);
      setLoading(true);
      axios
        .get('/api/v1/search/', {
          params: {
            type: searchType,
            comb_type: combSearchType,
            genetic_type: geneticSearchType,
            clinical_type: clinicalSearchType,
            analysis_type: analysisSearchType,
            ...searchInputsState
          },
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        .then(response => {
          console.log(response.data.access_token);
          setdata(response.data);
          setLoading(false);
          setLastSearchType(searchType);
          if (searchType === 'combination') {
            setLastCombSearchType(combSearchType);
          } else if (searchType === 'genetic') {
            setLastGeneticSearchType(geneticSearchType);
          } else if (searchType === 'clinical') {
            setLastClinicalSearchType(clinicalSearchType);
          } else if (searchType === 'analysis') {
            setLastAnalysisSearchType(analysisSearchType);
          }
        })
        .catch((error) => {
          setLoading(false);
          setError(true);
          setdata([]);
          if (error.response) {
            console.log(error.response)
            console.log(error.response.status)
            console.log(error.response.headers)
          }
          if (error.response.status === 401 && error.response.data.msg === "Token has expired") {
            removeToken();
            setPage('home');
          }
        });
    }
  };

  function onPersonHover(e, personID) {
    setPersonLoading(true);
    setPersonData([]);
    axios.get('/api/v1/search/', {
      params: { type: 'person', ids: personID },
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(response => {
      setPersonData(response.data)
      setPersonLoading(false);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }

      setPersonLoading(false);
      setError(true);
    });
  }

  function logOut() {
    axios({
      method: "POST",
      url: "/api/logout",
    })
      .then((response) => {
        removeToken();
        response.status;
        setPage('home');
      }).catch((error) => {
        if (error.response) {
          console.log(error.response)
          console.log(error.response.status)
          console.log(error.response.headers)
        }
      })
  }

  const domainOptions = [
    { value: 'Condition', label: 'Condition' },
    { value: 'Drug', label: 'Drug' },
    { value: 'Measurement', label: 'Measurement' },
    { value: 'Observation', label: 'Observation' },
    { value: 'Procedure', label: 'Procedure' },
    { value: 'Visit', label: 'Visit' },
    { value: 'Demographic', label: 'Demographic' }
  ];

  const updateCohortKeys = (inputValue) => {
    if (domainOptions.some(i => i.value === inputValue)) {
      setFilteredCohorts(cohortKeys.filter(i => i.domain_id === inputValue));
    }
  };

  const metadataOptions = [
    { value: 'sequence', label: 'sequence' },
    { value: 'company', label: 'company' },
    { value: 'seq_machine', label: 'seq_machine' },
    { value: 'seq_protocol', label: 'seq_protocol' },
    { value: 'coverage', label: 'coverage' },
    { value: 'alignment_protocol', label: 'alignment_protocol' },
    { value: 'variant_calling', label: 'variant_calling' }
  ];

  const updateMetadataKeys = (inputValue) => {
    if (metadataOptions.some(i => i.value === inputValue)) {
      setFilteredMetadata(metadataMap.filter(i => i.metadata_type === inputValue));
    }
  };

  const search_type = {
    search_type: searchType,
    set_search_type: setSearchType,
    clinical_search_type: clinicalSearchType,
    set_clinical_search_type: setClinicalSearchType,
    comb_search_type: combSearchType,
    set_comb_search_type: setCombSearchType,
    genetic_search_type: geneticSearchType,
    set_genetic_search_type: setGeneticSearchType,
    analysisSearchType: analysisSearchType,
    setAnalysisSearchType: setAnalysisSearchType,
    error: error,
    description: description,
    setDescription: setDescription
  };

  const search_inputs = {
    inputs: searchInputsState,
    dispatch: dispatch,
    on_inputs_submitted: onInputsSubmitted,
    on_person_hover: onPersonHover,
    person_data: personData,
    person_loading: personLoading,
    setPageWithChecks: setPageWithChecks,
    updateCohortKeys: updateCohortKeys,
    filteredCohorts: filteredCohorts,
    updateMetadataKeys: updateMetadataKeys,
    filteredMetadata: filteredMetadata
  };

  // Using useEffect for single rendering
  useEffect(() => {
    // Using fetch to fetch the api from
    // flask server it will be redirected to proxy
    fetchAuditLogs();
    const auditFetchIntervalId = setInterval(fetchAuditLogs, 2000);

    return () => {
      clearInterval(auditFetchIntervalId);
    };
  }, [page, token]);

  const fetchAuditLogs = () => {
    if (page === "admin" && token) {
      fetch('/api/v1/audit/?groupby=true', {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(res =>
        res.json().then(data => {
          // Setting a data from api
          setAuditData(
            data['logs'].sort((a, b) =>
              a.time < b.time ? 1 : a.time > b.time ? -1 : 0
            )
          );
          setAuditWalletData(data['wallets']);
        })
      ).catch((error) => {
        console.log(error);
      }
      );
    }
  };

  let table = '';

  if (
    !loading &&
    data &&
    searchType == 'clinical' &&
    searchType == lastSearchType
  ) {
    if (
      clinicalSearchType === 'domain' &&
      clinicalSearchType === lastClinicalSearchType
    ) {
      table = <ClinicalResultsTable results={data} />;
    } else if (
      clinicalSearchType === 'person' &&
      clinicalSearchType === lastClinicalSearchType
    ) {
      table = <ClinicalResultsTable results={data} />;
    }

  } else if (
    !loading &&
    data &&
    searchType === 'combination' &&
    searchType == lastSearchType
  ) {
    if (
      combSearchType === 'genetic' &&
      combSearchType === lastCombSearchType
    ) {
      table = <CombGeneticResultsTable results={data} />;
    } else if (
      combSearchType === 'clinical' &&
      combSearchType === lastCombSearchType
    ) {
      table = <ClinicalResultsTable results={data} />;
    }
  } else if (
    !loading &&
    data &&
    searchType === 'genetic' &&
    searchType == lastSearchType

  ) {
    if (
      geneticSearchType === 'variant' &&
      geneticSearchType === lastGeneticSearchType
    ) {
      table = <GeneticVariantResultsTable results={data} />;
    } else if (
      geneticSearchType === 'maf' &&
      geneticSearchType === lastGeneticSearchType
    ) {
      table = <GeneticMafResultsTable results={data} />;
    } else if (
      geneticSearchType === 'person' &&
      geneticSearchType === lastGeneticSearchType
    ) {
      table = <GeneticPersonResultsTable results={data} />;
    } else if (
      geneticSearchType === 'gene' &&
      geneticSearchType === lastGeneticSearchType
    ) {
      table = <GeneticGeneResultsTable results={data} />;
    } else if (
      geneticSearchType === 'annot' &&
      geneticSearchType === lastGeneticSearchType
    ) {
      table = <GeneticAnnotResultsTable results={data} />;
    }
  } else if (
    !loading &&
    data &&
    searchType === 'analysis' &&
    searchType == lastSearchType
  ) {
    if (
      analysisSearchType === 'pca' &&
      analysisSearchType === lastAnalysisSearchType
    ) {
      table = <AnalysisPcaResultsTable results={data} />;
    } else if (
      analysisSearchType === 'kinship' &&
      analysisSearchType === lastAnalysisSearchType
    ) {
      table = <AnalysisRelatednessResultsTable results={data} />;
    } else if (
      analysisSearchType === 'metadata' &&
      analysisSearchType === lastAnalysisSearchType
    ) {
      table = <AnalysisMetadataResultsTable results={data} />;
    }
  }

  return (
    <BrowserRouter>

      <Container fluid>
        <Navbar
          variant='light'
          fixed="top"
          className='navbar-standard navbar-theme bg-100 mb-1'
        >
          <Container fluid>
            <Navbar.Brand className="text-dark light__text-dark">
              PrecisionChain
            </Navbar.Brand>
            <Nav onSelect={selectedKey => setPageWithChecks(selectedKey)}>
              <Nav.Item>
                <Nav.Link eventKey="home"><Button variant='link'>HOME</Button></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="search"><Button variant='link'>SEARCH</Button></Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="admin"><Button variant='link'>ADMIN</Button></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                {token ? (
                  <Nav.Link eventKey='search'><Button variant='link' onClick={logOut}>LOGOUT</Button></Nav.Link>
                ) : (
                  <Nav.Link eventKey="login"><Button variant='link'>LOGIN</Button></Nav.Link>
                )
                }

              </Nav.Item>

            </Nav>
          </Container>
        </Navbar>
        <SearchTypeContext.Provider value={search_type}>
          <SearchInputsContext.Provider value={search_inputs}>

            <Section fluid>
              {page === 'search' ? (
                <Row>
                  <Col>
                    <Card>
                      <Card.Body>
                        <SearchBox />

                        {loading ? (
                          <Container>
                            <Row className="justify-content-center mt-4">
                              <Col xs={1}>
                                <Spinner animation="border" variant="success" />
                              </Col>
                            </Row>
                          </Container>
                        ) : (
                          table
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              ) : page === 'admin' && auditData ? (
                <Col xl={{ span: 12, order: 1 }}>
                  <RecentQueries tableData={auditData} perPage={10} />
                </Col>
              ) : page === 'login' && !token && token !== "" && token !== undefined ? (
                <Card>
                  <Card.Body>
                    <LoginForm setToken={setToken} setPage={setPage} />
                  </Card.Body>

                </Card>
              ) : page === 'home' ? (
                <Landing setPageWithChecks={setPageWithChecks} />
              ) : null}
            </Section>
          </SearchInputsContext.Provider>
        </SearchTypeContext.Provider >

      </Container >
    </BrowserRouter >
  );
}


const Landing = (props) => {

  return (
    <Section
      className="py-0 overflow-hidden light"
      image={dnaBackground}
      position="center"
      bgClassName="bg-light"
      bg="light"
      overlay
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          md={5}
          lg={5}
          xl={5}
          className="pb-7 pb-xl-9 text-center text-xl-start"
        >
          <h1 className="text-white bg-secondary fw-normal">
            Search
            <br />
            clinical and genetic
            <br />
            information all in one place
          </h1>
          <p className="text-white bg-secondary fw-bold mt-3">
            PrecisionChain enables secure storage and querying of clinical and genetic data using the blockchain technology
          </p>
          <Button
            variant="primary"
            size="lg"
            type='link'
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            onClick={() => props.setPageWithChecks('search')}
          >
            Start searching
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Button>

        </Col>
        <Col
          xl={{ span: 6, offset: 1 }}
          className="mt-4 mt-xl-0"
        >
          <Row className='justify-content-center align-items-center mb-4'>
            <ReactPlayer url={demoVideo} controls={true} />

          </Row>

        </Col>

      </Row>
    </Section>


  )
}

Landing.propTypes = {
  setPageWithChecks: PropTypes.func
}




function searchInputsReducer(state, action) {
  switch (action.type) {
    case 'multi':
      return { ...state, ...action.payload };
    case 'chromosome':
      return { ...state, chromosome: action.value };
    case 'annot':
      return { ...state, annot: action.value };
    case 'gene':
      return { ...state, gene: action.value };
    case 'ir':
      return { ...state, ir: action.value };
    case 'ck':
      return { ...state, ck: action.value };
    case 'positions':
      return { ...state, positions: action.value };
    case 'sk':
      return { ...state, sk: action.value };
    case 'gt':
      return { ...state, gt: action.value };
    case 'person_ids':
      return { ...state, person_ids: action.value };
    case 'metadata':
      return { ...state, metadata: action.value };
    case 'sampleId':
      return { ...state, sampleId: action.value };
    case 'kSearch':
      return { ...state, kSearch: action.value };
  }

}



export function more_text(length) {
  if (length > 1) {
    return ' and ' + (length - 1) + ' more';
  } else {
    return '';
  }
}

export function variant_text(item) {
  if (item.ref_allele && item.alt_allele) {
    return item.chr + ':' + item.variant + ':' + item.ref_allele + ':' + item.alt_allele;
  } else {
    return item.chr + ':' + item.variant;
  }
}

export function extract_person_ids(item) {
  const keys = Object.keys(item);
  var ids = [];
  keys.map(idx => {
    if (idx.startsWith('gt_')) {
      ids.push(idx.split('gt_')[1]);
    }
  });
  return ids;
}

export const genotype_map = {
  "0|0": "0",
  "0|1": "1",
  "1|0": "1",
  "1|1": "2"
}

export default App;
