const metadaMap = [
  {
    value: 'WGS',
    label: 'WGS',
    metadata_type: 'sequence'
  },
  {
    value: 'WES',
    label: 'WES',
    metadata_type: 'sequence'
  },
  {
    value: 'Affymetrix',
    label: 'Affymetrix',
    metadata_type: 'sequence'
  },
  {
    value: 'BeadChip',
    label: 'BeadChip',
    metadata_type: 'sequence'
  },
  {
    value: 'Oxford_Nanopore',
    label: 'Oxford_Nanopore',
    metadata_type: 'company'
  },
  {
    value: 'Ultima',
    label: 'Ultima',
    metadata_type: 'company'
  },
  {
    value: 'Illumina',
    label: 'Illumina',
    metadata_type: 'company'
  },
  {
    value: 'Affymetrix',
    label: 'Affymetrix',
    metadata_type: 'company'
  },
  {
    value: 'Illuumina',
    label: 'Illuumina',
    metadata_type: 'company'
  },
  {
    value: 'PromethION',
    label: 'PromethION',
    metadata_type: 'seq_machine'
  },
  {
    value: 'UG100',
    label: 'UG100',
    metadata_type: 'seq_machine'
  },
  {
    value: 'NovaSeq_6000',
    label: 'NovaSeq_6000',
    metadata_type: 'seq_machine'
  },
  {
    value: 'P2Solo',
    label: 'P2Solo',
    metadata_type: 'seq_machine'
  },
  {
    value: 'Next_1000',
    label: 'Next_1000',
    metadata_type: 'seq_machine'
  },
  {
    value: 'GeneChip_Scanner_3000',
    label: 'GeneChip_Scanner_3000',
    metadata_type: 'seq_machine'
  },
  {
    value: 'Infinium_global_screening',
    label: 'Infinium_global_screening',
    metadata_type: 'seq_machine'
  },
  {
    value: 'Infinium_XT',
    label: 'Infinium_XT',
    metadata_type: 'seq_machine'
  },
  {
    value: 'NGS',
    label: 'NGS',
    metadata_type: 'seq_protocol'
  },
  {
    value: 'HISAT2',
    label: 'HISAT2',
    metadata_type: 'alignment_protocol'
  },
  {
    value: 'Bwa',
    label: 'Bwa',
    metadata_type: 'alignment_protocol'
  },
  {
    value: 'FreeBayes',
    label: 'FreeBayes',
    metadata_type: 'variant_calling'
  },
  {
    value: 'GATK',
    label: 'GATK',
    metadata_type: 'variant_calling'
  },
  {
    value: 'Power_tools',
    label: 'Power_tools',
    metadata_type: 'variant_calling'
  },
  {
    value: 'bioconductor',
    label: 'bioconductor',
    metadata_type: 'variant_calling'
  },
  {
    value: 'GenomeStudio',
    label: 'GenomeStudio',
    metadata_type: 'variant_calling'
  },
  {
    value: 'BeadStudio',
    label: 'BeadStudio',
    metadata_type: 'variant_calling'
  },
  {
    value: 30.0,
    label: 30.0,
    metadata_type: 'coverage'
  },
  {
    value: 60.0,
    label: 60.0,
    metadata_type: 'coverage'
  },
  {
    value: 90.0,
    label: 90.0,
    metadata_type: 'coverage'
  },
  {
    value: 100.0,
    label: 100.0,
    metadata_type: 'coverage'
  },
  {
    value: 120.0,
    label: 120.0,
    metadata_type: 'coverage'
  },
  {
    value: 180.0,
    label: 180.0,
    metadata_type: 'coverage'
  },
  {
    value: 80.0,
    label: 80.0,
    metadata_type: 'coverage'
  }
];

export default metadaMap;
