import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInputsContext } from 'App';
import { useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

export function AnalysisRelatednessInputForm() {
  const {
    inputs,
    dispatch,
    on_inputs_submitted: onInputsSubmitted
  } = useContext(SearchInputsContext);

  return (
    <Form onKeyUp={onInputsSubmitted}>
      <Row>
        <Form.Group as={Col} controlId="sample" xs={7}>
          <Form.Label>Sample ID</Form.Label>
          <Form.Control
            type="text"
            placeholder="sample id"
            defaultValue={inputs.sampleId ? inputs.sampleId : undefined}
            onChange={event =>
              dispatch({
                type: 'sampleId',
                value: event.target.value
              })
            }
          />
        </Form.Group>
        <Button
          xs={2}
          as={Col}
          id="submit-inputs"
          variant="falcon-default"
          className="mt-4"
          type="submit"
          onClick={onInputsSubmitted}
        >
          <span>
            <FontAwesomeIcon icon="search" /> Search
          </span>
        </Button>
      </Row>
      <Button
        onClick={() => {
          dispatch({
            type: 'sampleId',
            value: '110617,35058,58089'
          });
          document.getElementById('sample').value = '110617,35058,58089';
        }}
        variant="link"
        className="text-start mt-2"
        size="sm"
        as={Col}
      >
        Click to prefill sample ID
      </Button>
    </Form>
  );
}
