import { SearchInputsContext } from 'App';
import groupBy from 'lodash/groupBy';
import { useContext } from 'react';
import { Button, Card, Tab, Table, Tabs } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Persons } from './Persons';

export function ClinicalResultsTable(data) {
  const { on_person_hover: onPersonHover, person_data: personData } =
    useContext(SearchInputsContext);

  const results = data.results;
  const grouped_data = groupBy(results, 'occurence_type');
  const person_popover = Persons(personData);

  return (
    <Card>
      <Card.Body>
        <Card.Title>Clinical Results</Card.Title>

        <Tabs id="clinical-results">
          {grouped_data['condition'] ? (
            <Tab
              eventKey="condition"
              title="Condition Occurrence"
              className="border-bottom border-x p-3"
            >
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Person ID</th>
                    <th>Condition Occurence ID</th>
                    <th>Condition Concept ID</th>
                    <th>Condition Start Date</th>
                    <th>Condition End Date</th>
                    <th>Visit Occurence ID</th>
                  </tr>
                </thead>
                <tbody>
                  {grouped_data['condition'] &&
                    grouped_data['condition'].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{ paddingLeft: '1px', paddingRight: '1px' }}
                          >
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={person_popover}
                              rootClose={true}
                            >
                              <Button
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                variant="link"
                                onClick={e => onPersonHover(e, item.person_id)}
                              >
                                {item.person_id}
                              </Button>
                            </OverlayTrigger>
                          </td>
                          <td>{item.condition_occurrence_id}</td>
                          <td>
                            {item.condition_concept_id +
                              ' - ' +
                              item.concept_name}
                          </td>
                          <td>{item.condition_start_date}</td>
                          <td>{item.condition_end_date}</td>
                          <td>{item.visit_occurrence_id}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Tab>
          ) : null}

          {grouped_data['drug'] ? (
            <Tab
              eventKey="drug"
              title="Drug Exposure"
              className="border-bottom border-x p-3"
            >
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Person ID</th>
                    <th>Drug Exposure ID</th>
                    <th>Drug Concept ID</th>
                    <th>Drug Exposure Start</th>
                    <th>Drug Exposure End</th>
                    <th>Quantity</th>
                    <th>Visit Occurence ID</th>
                    <th>Route Resorce Value</th>
                    <th>Dose Unit Source Value</th>
                  </tr>
                </thead>
                <tbody>
                  {grouped_data['drug'] &&
                    grouped_data['drug'].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{ paddingLeft: '1px', paddingRight: '1px' }}
                          >
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={person_popover}
                              rootClose={true}
                            >
                              <Button
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                variant="link"
                                onClick={e => onPersonHover(e, item.person_id)}
                              >
                                {item.person_id}
                              </Button>
                            </OverlayTrigger>
                          </td>
                          <td>{item.drug_exposure_id}</td>
                          <td>
                            {item.drug_concept_id + ' - ' + item.concept_name}
                          </td>
                          <td>{item.drug_exposure_start_date}</td>
                          <td>{item.drug_exposure_end_date}</td>
                          <td>{item.quantity}</td>
                          <td>{item.visit_occurrence_id}</td>
                          <td>{item.route_source_value}</td>
                          <td>{item.dose_unit_source_value}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Tab>
          ) : null}

          {grouped_data['measurement'] ? (
            <Tab
              eventKey="measurement"
              title="measurement"
              className="border-bottom border-x p-3"
            >
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Person ID</th>
                    <th>Measurement ID</th>
                    <th>Measurement Concept ID</th>
                    <th>Measurement Date</th>
                    <th>Value as Number</th>
                    <th>Visit Occurence ID</th>
                    <th>Unit Source Value</th>
                  </tr>
                </thead>
                <tbody>
                  {grouped_data['measurement'] &&
                    grouped_data['measurement'].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{ paddingLeft: '1px', paddingRight: '1px' }}
                          >
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={person_popover}
                              rootClose={true}
                            >
                              <Button
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                variant="link"
                                onClick={e => onPersonHover(e, item.person_id)}
                              >
                                {item.person_id}
                              </Button>
                            </OverlayTrigger>
                          </td>
                          <td>{item.measurement_id}</td>
                          <td>
                            {item.measurement_concept_id +
                              ' - ' +
                              item.concept_name}
                          </td>
                          <td>{item.measurement_date}</td>
                          <td>{item.value_as_number}</td>
                          <td>{item.visit_occurrence_id}</td>
                          <td>{item.unit_source_value}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Tab>
          ) : null}

          {grouped_data['visit'] ? (
            <Tab
              eventKey="visit"
              title="Visit Occurrence"
              className="border-bottom border-x p-3"
            >
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Person ID</th>
                    <th>Preeceding Visit Occurence ID</th>
                    <th>Visit Concept ID</th>
                    <th>Visit Start Date</th>
                    <th>Visit End Date</th>
                    <th>Visit Occurence ID</th>
                  </tr>
                </thead>
                <tbody>
                  {grouped_data['visit'] &&
                    grouped_data['visit'].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{ paddingLeft: '1px', paddingRight: '1px' }}
                          >
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={person_popover}
                              rootClose={true}
                            >
                              <Button
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                variant="link"
                                onClick={e => onPersonHover(e, item.person_id)}
                              >
                                {item.person_id}
                              </Button>
                            </OverlayTrigger>
                          </td>
                          <td>{item.preceding_visit_occurrence_id}</td>
                          <td>
                            {item.visit_concept_id + ' - ' + item.concept_name}
                          </td>
                          <td>{item.visit_start_date}</td>
                          <td>{item.visit_end_date}</td>
                          <td>{item.visit_occurrence_id}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Tab>
          ) : null}

          {grouped_data['procedure'] ? (
            <Tab
              eventKey="procedure"
              title="Procedure Occurrence"
              className="border-bottom border-x p-3"
            >
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Person ID</th>
                    <th>Procedure Concept ID</th>
                    <th>Procedure Date</th>
                    <th>Procedure Occurence Date</th>
                    <th>Visit Occurence ID</th>
                  </tr>
                </thead>
                <tbody>
                  {grouped_data['procedure'] &&
                    grouped_data['procedure'].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{ paddingLeft: '1px', paddingRight: '1px' }}
                          >
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={person_popover}
                              rootClose={true}
                            >
                              <Button
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                variant="link"
                                onClick={e => onPersonHover(e, item.person_id)}
                              >
                                {item.person_id}
                              </Button>
                            </OverlayTrigger>
                          </td>
                          <td>
                            {item.procedure_concept_id +
                              ' - ' +
                              item.concept_name}
                          </td>
                          <td>{item.procedure_date}</td>
                          <td>{item.procedure_occurrence_id}</td>
                          <td>{item.visit_occurrence_id}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Tab>
          ) : null}

          {grouped_data['specimen'] ? (
            <Tab
              eventKey="specimen"
              title="Specimen"
              className="border-bottom border-x p-3"
            >
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Person ID</th>
                    <th>Specimen ID</th>
                    <th>Specimen Concept ID</th>
                    <th>Specimen Date</th>
                  </tr>
                </thead>
                <tbody>
                  {grouped_data['specimen'] &&
                    grouped_data['specimen'].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{ paddingLeft: '1px', paddingRight: '1px' }}
                          >
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={person_popover}
                              rootClose={true}
                            >
                              <Button
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                variant="link"
                                onClick={e => onPersonHover(e, item.person_id)}
                              >
                                {item.person_id}
                              </Button>
                            </OverlayTrigger>
                          </td>
                          <td>{item.specimen_id}</td>
                          <td>
                            {item.specimen_concept_id +
                              ' - ' +
                              item.concept_name}
                          </td>
                          <td>{item.specimen_date}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Tab>
          ) : null}
          {grouped_data['person'] ? (
            <Tab
              eventKey="person"
              title="Person"
              className="border-bottom border-x p-3"
            >
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Person ID</th>
                    <th>Year of Birth</th>
                    <th>Gender Source Value</th>
                    <th>Race Source Value</th>
                    <th>Ethnicity Value</th>
                  </tr>
                </thead>
                <tbody>
                  {grouped_data['person'] &&
                    grouped_data['person'].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{ paddingLeft: '1px', paddingRight: '1px' }}
                          >
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={person_popover}
                              rootClose={true}
                            >
                              <Button
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                variant="link"
                                onClick={e => onPersonHover(e, item.person_id)}
                              >
                                {item.person_id}
                              </Button>
                            </OverlayTrigger>
                          </td>
                          <td>{item.year_of_birth}</td>
                          <td>{item.gender_source_value}</td>
                          <td>{item.race_source_value}</td>
                          <td>{item.ethnicity_value}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Tab>
          ) : null}
          {grouped_data['device'] ? (
            <Tab
              eventKey="device"
              title="Device Exposure"
              className="border-bottom border-x p-3"
            >
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Person ID</th>
                    <th>Device Exposune ID</th>
                    <th>Device Concept ID</th>
                    <th>Device Exposure Start Date</th>
                    <th>Device Exposure End Date</th>
                    <th>Visit Occurence ID</th>
                  </tr>
                </thead>
                <tbody>
                  {grouped_data['device'] &&
                    grouped_data['device'].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{ paddingLeft: '1px', paddingRight: '1px' }}
                          >
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={person_popover}
                              rootClose={true}
                            >
                              <Button
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                variant="link"
                                onClick={e => onPersonHover(e, item.person_id)}
                              >
                                {item.person_id}
                              </Button>
                            </OverlayTrigger>
                          </td>
                          <td>{item.device_exposure_id}</td>
                          <td>
                            {item.device_concept_id + ' - ' + item.concept_name}
                          </td>
                          <td>{item.device_exposure_start_date}</td>
                          <td>{item.device_exposure_end_date}</td>
                          <td>{item.visit_occurrence_id}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Tab>
          ) : null}
          {grouped_data['observation'] ? (
            <Tab
              eventKey="observation"
              title="Observation"
              className="border-bottom border-x p-3"
            >
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Person ID</th>
                    <th>Observation ID</th>
                    <th>Observation Concept ID</th>
                    <th>Date</th>
                    <th>Value as Number</th>
                    <th>Visit Occurence ID</th>
                  </tr>
                </thead>
                <tbody>
                  {grouped_data['observation'] &&
                    grouped_data['observation'].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{ paddingLeft: '1px', paddingRight: '1px' }}
                          >
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={person_popover}
                              rootClose={true}
                            >
                              <Button
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                variant="link"
                                onClick={e => onPersonHover(e, item.person_id)}
                              >
                                {item.person_id}
                              </Button>
                            </OverlayTrigger>
                          </td>
                          <td>{item.observation_id}</td>
                          <td>
                            {item.observation_concept_id +
                              ' - ' +
                              item.concept_name}
                          </td>
                          <td>{item.observation_date}</td>
                          <td>{item.value_as_number}</td>
                          <td>{item.visit_occurrence_id}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Tab>
          ) : null}
        </Tabs>
      </Card.Body>
    </Card>
  );
}
