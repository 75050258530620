const cohortKeys = [
  {
    value: 373870,
    label: 'Neonatal dacryocystitis and conjunctivitis',
    domain_id: 'Condition'
  },
  {
    value: 40408719,
    label: 'Foreign body of skin of knee',
    domain_id: 'Condition'
  },
  { value: 4103172, label: 'Mouth position finding', domain_id: 'Condition' },
  {
    value: 37200478,
    label:
      'Sensorineural hearing loss, unilateral, left ear, with restricted hearing on the contralateral side',
    domain_id: 'Condition'
  },
  {
    value: 42538561,
    label: 'Neonatal hyperglycemia due to iatrogenic intravenous therapy',
    domain_id: 'Condition'
  },
  { value: 4087318, label: 'Erotic feeling', domain_id: 'Condition' },
  {
    value: 45578769,
    label:
      'Laceration without foreign body of left middle finger without damage to nail, sequela',
    domain_id: 'Condition'
  },
  {
    value: 37402857,
    label:
      'Maternal care for (suspected) chromosomal abnormality in fetus, Trisomy 21, fetus 4',
    domain_id: 'Condition'
  },
  {
    value: 4316332,
    label: 'Accidental poisoning by fluoroacetate',
    domain_id: 'Condition'
  },
  {
    value: 45540249,
    label:
      'Salter-Harris Type I physeal fracture of lower end of radius, unspecified arm, subsequent encounter for fracture with nonunion',
    domain_id: 'Condition'
  },
  { value: 8507, label: 'MALE', domain_id: 'Demographic' },
  { value: 4215271, label: 'Gender unspecified', domain_id: 'Demographic' },
  {
    value: 4251434,
    label: 'Surgically transgendered transsexual, male-to-female',
    domain_id: 'Demographic'
  },
  { value: 36675593, label: 'Non-binary gender', domain_id: 'Demographic' },
  { value: 4214687, label: 'Gender unknown', domain_id: 'Demographic' },
  { value: 8521, label: 'OTHER', domain_id: 'Demographic' },
  { value: 4268709, label: 'Gender finding', domain_id: 'Demographic' },
  { value: 8532, label: 'FEMALE', domain_id: 'Demographic' },
  { value: 8551, label: 'UNKNOWN', domain_id: 'Demographic' },
  { value: 45766035, label: 'Feminine gender', domain_id: 'Demographic' },
  {
    value: 19115479,
    label: 'Nonoxynol-9 0.05 MG/MG [Genexol]',
    domain_id: 'Drug'
  },
  { value: 19082638, label: 'Theophyll-GG', domain_id: 'Drug' },
  {
    value: 46141432,
    label: 'Sumatriptan 50mg tablets (Niche Generics Ltd) 6 tablet',
    domain_id: 'Drug'
  },
  {
    value: 40064800,
    label: 'Meclizine / Pyridoxine Syrup',
    domain_id: 'Drug'
  },
  {
    value: 46105138,
    label:
      'Amiodarone 200mg tablets (Zentiva Pharma UK Ltd) 28 tablet 2 x 14 tablets',
    domain_id: 'Drug'
  },
  {
    value: 46192703,
    label:
      'Coal tar solution 3% in Clobetasone 0.05% ointment (Special Order) 1 gram',
    domain_id: 'Drug'
  },
  {
    value: 40064081,
    label: 'methadyl acetate Oral Solution',
    domain_id: 'Drug'
  },
  {
    value: 46278143,
    label:
      'Lamotrigine 5mg dispersible tablets sugar free (Mawdsley-Brooks & Company Ltd)',
    domain_id: 'Drug'
  },
  {
    value: 46119769,
    label:
      'Intraven potassium chloride 0.3% (potassium 20mmol/500ml) / sodium chloride 0.9% infusion 500ml bags (Terumo BCT Ltd) 1 bag',
    domain_id: 'Drug'
  },
  {
    value: 36688848,
    label:
      'Lidocaine 200mg/20ml (1%) solution for injection ampoules 100 ampoule',
    domain_id: 'Drug'
  },
  {
    value: 3027501,
    label: 'Physical findings of Groin Narrative',
    domain_id: 'Measurement'
  },
  {
    value: 46235523,
    label: 'Lysophosphatidylcholine(18:0) [Moles/volume] in Plasma',
    domain_id: 'Measurement'
  },
  {
    value: 36032205,
    label:
      'Cryptococcus gattii+neoformans DNA [Presence] by NAA with non-probe detection in Positive blood culture',
    domain_id: 'Measurement'
  },
  {
    value: 40774790,
    label: 'CD19+IgA+ cells | XXX',
    domain_id: 'Measurement'
  },
  {
    value: 4294768,
    label: 'Ward urine dip stick testing',
    domain_id: 'Measurement'
  },
  {
    value: 3019885,
    label: 'Itraconazole [Mass/volume] in Serum or Plasma',
    domain_id: 'Measurement'
  },
  {
    value: 3019705,
    label: 'C peptide/Creatinine [Mass Ratio] in 24 hour Urine',
    domain_id: 'Measurement'
  },
  {
    value: 37048536,
    label:
      'Peanut (Arachis hypogaea) recombinant (rAra h) 9 IgE | Serum | Allergy',
    domain_id: 'Measurement'
  },
  {
    value: 46270595,
    label: 'Defense force pre-deployment health screen',
    domain_id: 'Measurement'
  },
  {
    value: 37046907,
    label: 'Glyoxylate | Urine | Chemistry - non-challenge',
    domain_id: 'Measurement'
  },
  {
    value: 3618958,
    label: 'Ultramax 53425 25mm pre-cut opaque drainable ileostomy bag',
    domain_id: 'Observation'
  },
  {
    value: 3581133,
    label: 'Sorbichew 5mg chewable tablet',
    domain_id: 'Observation'
  },
  {
    value: 3593738,
    label: 'Dansac Combi Ileo F 03522-1010 22mm opaque drainable ileostomy bag',
    domain_id: 'Observation'
  },
  {
    value: 37061797,
    label: 'Solanum tuberosum Ab.IgE.RAST class',
    domain_id: 'Observation'
  },
  {
    value: 1004827,
    label: 'How often were you too tired to leave the house in past 7 days',
    domain_id: 'Observation'
  },
  {
    value: 3579727,
    label: 'Cocaine+adrenaline 5%/0.02% eye drops - product',
    domain_id: 'Observation'
  },
  {
    value: 3642274,
    label: 'LOWER LIMB LYMPHOEDEMA GARMENTS(159)',
    domain_id: 'Observation'
  },
  {
    value: 45556520,
    label:
      'Person injured in collision between other motor vehicle and two- or three-wheeled motor vehicle, nontraffic, subsequent encounter',
    domain_id: 'Observation'
  },
  {
    value: 40299750,
    label: 'Child removed from protection register',
    domain_id: 'Observation'
  },
  { value: 4254674, label: 'Neometastrongylus', domain_id: 'Observation' },
  {
    value: 42895362,
    label:
      'Dilation of Face Artery with Three Drug-eluting Intraluminal Devices, Percutaneous Endoscopic Approach',
    domain_id: 'Procedure'
  },
  {
    value: 1533658,
    label:
      'Medical and Surgical @ Male Reproductive System @ Revision @ Prostate and Seminal Vesicles @ Via Natural or Artificial Opening @ Other Device',
    domain_id: 'Procedure'
  },
  {
    value: 3534755,
    label: 'Primary closure of defect of interatrial septum NEC',
    domain_id: 'Procedure'
  },
  {
    value: 2728764,
    label: 'Excision of Left Hand Artery, Percutaneous Endoscopic Approach',
    domain_id: 'Procedure'
  },
  {
    value: 4148772,
    label: 'Psychologic test, Benton visual retention test',
    domain_id: 'Procedure'
  },
  {
    value: 4043592,
    label: 'Eikonometric examination including prescribing lenses',
    domain_id: 'Procedure'
  },
  {
    value: 2787667,
    label: 'Fluoroscopy of Bilateral Lungs',
    domain_id: 'Procedure'
  },
  {
    value: 2873539,
    label:
      'Medical and Surgical @ Lower Joints @ Removal @ Knee Joint, Right @ Open @ Infusion Device',
    domain_id: 'Procedure'
  },
  {
    value: 2762708,
    label: 'Excision of Right Pelvic Bone, Open Approach, Diagnostic',
    domain_id: 'Procedure'
  },
  { value: 2000950, label: 'Other mastoidectomy', domain_id: 'Procedure' },
  { value: 4139501, label: 'Health center', domain_id: 'Visit' },
  {
    value: 45770546,
    label: 'Resource centre on NHS hospital site',
    domain_id: 'Visit'
  },
  { value: 32252, label: 'Hospital Outpatient', domain_id: 'Visit' },
  {
    value: 32342,
    label:
      'Hospital -Laboratory Services provided to non-pt @ Replacement of Prior Claim',
    domain_id: 'Visit'
  },
  {
    value: 2721644,
    label:
      'Contracted home health agency services, all services provided under contract, per day',
    domain_id: 'Visit'
  },
  {
    value: 2617986,
    label:
      'Oncology; primary focus of visit; surveillance for disease recurrence for patient who has completed definitive cancer-directed therapy and currently lacks evidence of recurrent disease; cancer directed therapy might be considered in the future (for use...',
    domain_id: 'Visit'
  },
  {
    value: 2614668,
    label:
      'Ambulance service, advanced life support, emergency transport, level 1 (als 1 - emergency)',
    domain_id: 'Visit'
  },
  {
    value: 32181,
    label: 'Residential Facility @ CWF Initiated Adjustment Claim',
    domain_id: 'Visit'
  },
  {
    value: 45770541,
    label: 'NHS day care facility on NHS hospital site',
    domain_id: 'Visit'
  },
  {
    value: 4329847,
    label: 'Myocardial infarction',
    domain_id: 'Condition'
  },
  { value: 4089192, label: 'Podiatry outreach clinic', domain_id: 'Visit' },
  { value: 201826, label: 'Type 2 diabetes mellitus', domain_id: 'Condition' }
];

export default cohortKeys;
