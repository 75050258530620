import { Card, Tab, Table, Tabs } from 'react-bootstrap';

import groupBy from 'lodash/groupBy';

export default function GeneticAnnotResultsTable(data) {
  const grouped_data = groupBy(data.results, 'annot_type');

  return (
    <Card>
      <Card.Body>
        <Card.Title>Genetic Annotation Results</Card.Title>

        <Tabs id="genetic-annot-results">
          {grouped_data['cadd'] ? (
            <Tab
              eventKey="cadd"
              title="cadd"
              className="border-bottom border-x p-3"
            >
              <Table striped bordered hover responsive="sm">
                <thead>
                  <tr>
                    <th>GeneID</th>
                    <th>GeneName</th>
                    <th>Position</th>
                    <th>AnnoType</th>
                    <th>PHRED</th>
                    <th>RawScore</th>
                    <th>ConsScore</th>
                  </tr>
                </thead>
                <tbody>
                  {grouped_data['cadd'] &&
                    grouped_data['cadd'].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.GeneID}</td>
                          <td>{item.GeneName}</td>
                          <td>{item.Position}</td>
                          <td>{item.AnnoType}</td>
                          <td>{item.PHRED}</td>
                          <td>{item.RawScore}</td>
                          <td>{item.ConsScore}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Tab>
          ) : null}

          {grouped_data['clinvar'] ? (
            <Tab
              eventKey="clinvar"
              title="clinvar"
              className="border-bottom border-x p-3"
            >
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>GeneID</th>
                    <th>GeneName</th>
                    <th>Position</th>
                    <th>ClinicalSignificance</th>
                    <th>ClinSigSimple</th>
                    <th>PhenotypeList</th>
                  </tr>
                </thead>
                <tbody>
                  {grouped_data['clinvar'] &&
                    grouped_data['clinvar'].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.GeneID}</td>
                          <td>{item.GeneName}</td>
                          <td>{item.Position}</td>
                          <td>{item.ClinicalSignificance}</td>
                          <td>{item.ClinSigSimple}</td>
                          <td>{item.PhenotypeList}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Tab>
          ) : null}

          {grouped_data['vep'] ? (
            <Tab
              eventKey="vep"
              title="vep"
              className="border-bottom border-x p-3"
            >
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>GeneID</th>
                    <th>GeneName</th>
                    <th>Position</th>
                    <th>Uploaded_variation</th>
                    <th>Consequence</th>
                  </tr>
                </thead>
                <tbody>
                  {grouped_data['vep'] &&
                    grouped_data['vep'].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.GeneID}</td>
                          <td>{item.GeneName}</td>
                          <td>{item.Position}</td>
                          <td>{item.Uploaded_variation}</td>
                          <td>{item.Consequence}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Tab>
          ) : null}
        </Tabs>
      </Card.Body>
    </Card>
  );
}
