import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInputsContext } from 'App';
import { useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

export function GeneticPersonInputForm() {
  const {
    inputs,
    dispatch,
    on_inputs_submitted: onInputsSubmitted
  } = useContext(SearchInputsContext);

  return (
    <Form onKeyUp={onInputsSubmitted}>
      <Row>
        <Form.Group as={Col} controlId="chromosome" xs={2}>
          <Form.Label>Chromosome</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: 1 or 1, 3"
            defaultValue={inputs.chromosome ? inputs.chromosome : undefined}
            onChange={event =>
              dispatch({
                type: 'chromosome',
                value: event.target.value
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} controlId="person_ids" xs={3}>
          <Form.Label>Person ID</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: 1741351032930224901"
            defaultValue={inputs.person_ids ? inputs.person_ids : undefined}
            onChange={event =>
              dispatch({
                type: 'person_ids',
                value: event.target.value
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} controlId="positions" xs={3}>
          <Form.Label>Positions</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: 13011, 13110, 13116"
            defaultValue={inputs.positions ? inputs.positions : undefined}
            onChange={event =>
              dispatch({
                type: 'positions',
                value: event.target.value
              })
            }
          />
        </Form.Group>

        <Button
          xs={2}
          as={Col}
          id="submit-inputs"
          variant="falcon-default"
          className="mt-4"
          type="submit"
          onClick={onInputsSubmitted}
        >
          <span>
            <FontAwesomeIcon icon="search" /> Search
          </span>
        </Button>
      </Row>
      <Button
        onClick={() => {
          dispatch({
            type: 'multi',
            payload: {
              chromosome: '3',
              positions: '101612408,100054490',
              person_ids: '110617,35058,44098'
            }
          });
          document.getElementById('chromosome').value = '3';
          document.getElementById('positions').value = '101612408,100054490';
          document.getElementById('person_ids').value = '110617,35058,44098';
        }}
        className="text-start mt-2"
        as={Col}
        size="sm"
        variant="link"
      >
        Click to prefill sample search keys
      </Button>
    </Form>
  );
}
