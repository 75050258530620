import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInputsContext } from 'App';
import { useContext, useRef } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

export function GeneticVariantInputForm() {
  const selectElement = useRef();
  const selectElementType = useRef();

  const {
    inputs,
    dispatch,
    filteredMetadata,
    updateMetadataKeys,
    on_inputs_submitted: onInputsSubmitted
  } = useContext(SearchInputsContext);

  const metadataOptions = [
    { value: 'sequence', label: 'sequence' },
    { value: 'company', label: 'company' },
    { value: 'seq_machine', label: 'seq_machine' },
    { value: 'seq_protocol', label: 'seq_protocol' },
    { value: 'coverage', label: 'coverage' },
    { value: 'alignment_protocol', label: 'alignment_protocol' },
    { value: 'variant_calling', label: 'variant_calling' }
  ];

  return (
    <Form onKeyUp={onInputsSubmitted}>
      <Row>
        <Form.Group as={Col} controlId="chromosome" xs={1}>
          <Form.Label>Chromosome</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: 3"
            defaultValue={inputs.chromosome ? inputs.chromosome : undefined}
            onChange={event =>
              dispatch({
                type: 'chromosome',
                value: event.target.value
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} controlId="positions" xs={3}>
          <Form.Label>Positions</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: 171016588,171017235,171016649"
            defaultValue={inputs.positions ? inputs.positions : undefined}
            onChange={event =>
              dispatch({
                type: 'positions',
                value: event.target.value
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} controlId="gt" xs={2}>
          <Form.Label>Genotype</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: 0, 1 or 2"
            defaultValue={inputs.gt ? inputs.gt : undefined}
            onChange={event =>
              dispatch({
                type: 'gt',
                value: event.target.value
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} controlId="metadata-type" xs={2}>
          <Form.Label>Metadata Category</Form.Label>
          <CreatableSelect
            closeMenuOnSelect={true}
            options={metadataOptions}
            placeholder="Select metadata"
            onChange={target => {
              updateMetadataKeys(target.value);
            }}
            id="metadataType"
            ref={selectElementType}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="metadata" xs={2}>
          <Form.Label>Filter by Metadata (optional)</Form.Label>
          <CreatableSelect
            closeMenuOnSelect={true}
            options={filteredMetadata}
            placeholder="Select metadata"
            onChange={target =>
              dispatch({
                type: 'metadata',
                value: target.value
              })
            }
            id="metadataVal"
            ref={selectElement}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          />
        </Form.Group>
        <Button
          xs={2}
          as={Col}
          id="submit-inputs"
          variant="falcon-default"
          className="mt-4"
          type="submit"
          onClick={onInputsSubmitted}
        >
          <span>
            <FontAwesomeIcon icon="search" /> Search
          </span>
        </Button>
      </Row>
      <Button
        onClick={() => {
          dispatch({
            type: 'multi',
            payload: {
              chromosome: '1',
              positions: '64125544,961294,966728',
              gt: '1,0',
              metadata: ''
            }
          });
          document.getElementById('chromosome').value = '1';
          document.getElementById('positions').value = '64125544,961294,966728';
          document.getElementById('gt').value = '1,0';
        }}
        className="text-start mt-2"
        as={Col}
        size="sm"
        variant="link"
      >
        Click to prefill sample search keys
      </Button>
    </Form>
  );
}
