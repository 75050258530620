import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInputsContext } from 'App';
import { useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

export function GeneticMafInputForm() {
  const {
    inputs,
    dispatch,
    on_inputs_submitted: onInputsSubmitted
  } = useContext(SearchInputsContext);

  return (
    <Form onKeyUp={onInputsSubmitted}>
      <Row>
        <Form.Group as={Col} controlId="chromosome" xs={2}>
          <Form.Label>Chromosome</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: 1"
            defaultValue={inputs.chromosome ? inputs.chromosome : undefined}
            onChange={event =>
              dispatch({
                type: 'chromosome',
                value: event.target.value
              })
            }
          />
        </Form.Group>

        <Form.Group as={Col} controlId="ir" xs={5}>
          <Form.Label>MAF Range</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: 0.1-0.2"
            defaultValue={inputs.ir ? inputs.ir : undefined}
            onChange={event =>
              dispatch({
                type: 'ir',
                value: event.target.value
              })
            }
          />
        </Form.Group>
        <Button
          xs={2}
          as={Col}
          id="submit-inputs"
          variant="falcon-default"
          className="mt-4"
          type="submit"
          onClick={onInputsSubmitted}
        >
          <span>
            <FontAwesomeIcon icon="search" /> Search
          </span>
        </Button>
      </Row>
      <Button
        onClick={() => {
          dispatch({
            type: 'multi',
            payload: { chromosome: '22', ir: '0.1-0.2' }
          });
          document.getElementById('chromosome').value = '22';
          document.getElementById('ir').value = '0.1-0.2';
        }}
        className="text-start mt-2"
        as={Col}
        size="sm"
        variant="link"
      >
        Click here to prefill sample search keys
      </Button>
    </Form>
  );
}
