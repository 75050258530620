import { genotype_map } from 'App';
import { Card, Table } from 'react-bootstrap';

export function GeneticMafResultsTable(data) {
  return (
    <Card>
      <Card.Body>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Variant</th>
              <th>Genotype</th>
              <th className="text-end">MAF</th>
            </tr>
          </thead>
          <tbody>
            {data.results &&
              data.results.map &&
              data.results.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.variant}</td>
                    <td>{genotype_map[item.gt]}</td>
                    <td className="text-end">{item.maf}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
